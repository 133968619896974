










































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { FundAppropriateRecordDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import { ElForm } from "element-ui/types/form";
import PagedTabsTableView from "@/components/PagedTableView/PagedTabsTableView.vue";

@Component({
  name: "FundAppropriateRecordList",
  components: {
    PagedTabsTableView,
    PagedTableView,
    AbSelect,
  },
})
export default class FundAppropriateRecordList extends Vue {
  @Ref() readonly pagedTableView!: any;
  @Ref() readonly dataForm!: ElForm;

  dialogFormVisible = false;
  dialogForm: any = {
    id: 0,
    newTime: undefined,
  };

  queryForm = {
    fundId: 0,
    projectId: 0,
    title: "",
    creatorUserName: "",
    fundName: "",
    projectName: "",
  };
  fundId = 0;
  fundName = "";
  projectId = 0;
  projectName = "";
  totalMoney = 0;

  @Watch("$route.params")
  changeRoute(newVal: any) {
    this.pagedTableView!.fetchData();
  }

  created() {
    if (this.$route.query.fundId) {
      this.fundId = Number(this.$route.query.fundId);
      this.queryForm.fundId = Number(this.$route.query.fundId);
      api.fund.get({ id: this.queryForm.fundId }).then((fund) => {
        this.fundName = fund!.name ?? "";
      });
    }
    if (this.$route.query.projectId) {
      this.projectId = Number(this.$route.query.projectId);
      this.queryForm.projectId = Number(this.$route.query.projectId);
      api.fundProject.get({ id: this.queryForm.projectId }).then((res) => {
        this.projectName = res!.name ?? "";
      });
    }
    this.fetchTotalMoney();
  }

  async fetchTotalMoney() {
    await api.fundAppropriateRecord
      .statisticsTotalAmount(this.queryForm)
      .then((res: number) => {
        this.totalMoney = res;
      });
  }

  // 查看详情
  jumpDetail(id: number) {
    this.$router.push({
      name: "officeApplicationPaymentDetail",
      params: {
        id: id!.toString(),
      },
    });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.$route.query.fundId) {
      params.fundId = Number(this.$route.query.fundId);
    } else {
      params.fundId = 0;
    }
    if (this.$route.query.projectId) {
      params.projectId = Number(this.$route.query.projectId);
    } else {
      params.projectId = 0;
    }
    return api.fundAppropriateRecord.getAll(params);
  }

  // 修改拨付时间
  changeTime(row: FundAppropriateRecordDto) {
    this.dialogForm.id = row.id;
    this.dialogForm.newTime = row.appropriateTime;
    this.dialogFormVisible = true;
  }

  async saveDialog() {
    console.log(this.dialogForm);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dialogForm.id > 0) {
          await api.fundAppropriateRecord.changeAppropriateTime({
            body: this.dialogForm,
          });
        }
        this.dialogFormVisible = false;
        this.$message.success("更新成功");
      }
    });
  }

  get pageHeadContent() {
    if (this.fundName) {
      return `基金【${this.fundName}】拨付记录`;
    } else if (this.projectName) {
      return `项目【${this.projectName}】拨付记录`;
    } else {
      return "Loading...";
    }
  }

  dialogRule = {
    newTime: [
      {
        required: true,
        message: "请输入拨付时间",
        trigger: "blur",
      },
    ],
  };
}
